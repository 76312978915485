import { ComponentRef } from '@wix/yoshi-flow-editor';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';

import { HelpId } from '../helpId';
import { EditorScriptContext } from '../types';
import { getWidgetComponentName } from '../utils';

const panelHeightByComponentClassic: Record<string, number> = {
  referFriendPage: 285,
  referralPageContent: 120,
  referFriendPageContent: 120,
  notificationBanner: 120,
};

const panelHeightByComponentStudio: Record<string, number> = {
  referFriendPage: 320,
  referralPageContent: 175,
  referFriendPageContent: 175,
  notificationBanner: 175,
};

export const openLayoutPanel = async (widgetRef: ComponentRef, context: EditorScriptContext) => {
  const { editorSDK, flowAPI } = context;
  const { translations, environment } = flowAPI;
  const { t } = translations;
  const componentName = await getWidgetComponentName(widgetRef, context);

  if (!componentName) {
    return;
  }

  const isWixStudio = environment.isEditorX;
  const panelHeightByComponent = isWixStudio ? panelHeightByComponentStudio : panelHeightByComponentClassic;
  const height = panelHeightByComponent[componentName];

  await editorSDK.editor.openComponentPanel('', {
    title: t('layout-panel.title'),
    url: getPanelUrl(componentName, 'Layout'),
    type: editorSDK.editor.PanelType.Layout,
    helpId: HelpId.Layout,
    width: 288,
    height,
    componentRef: widgetRef,
    initialData: { widgetRef },
  });
};
